// @flow
import React from 'react';
import queryString from "query-string"
import _ from 'lodash';
import {Redirect, withRouter} from 'react-router-dom';
import Login from "../../components/Login";
import VersionLabel from "../../components/VersionLabel";
import './index.less';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import ShadowPanel from "../../components/ShadowPanel";
import VSMColorBar from "../../components/VSMColorBar";
import {compose} from "recompose";
import {withTranslation} from "react-i18next";
import NoAccessPanel, {nonAccessRoles} from "../../components/Login/noAccess";
import VSMLoadingAnimation from "../../components/VSMLoadingAnimation";
import keycloak from "../../keycloak";
import KeycloakLoginHandler from "../../components/KeycloakLoginHandler";

type Props = {
    match:{
        params: {
            email: String
        }
    },
    history: {
        location: any,
    }
}

type State = {
    userRole: any
}

class LoginRoute extends React.Component<Props> {

    state = {
        userRole: null
    }

    render() {
        const search = queryString.parse(_.get(this.props, 'history.location.search'));
        const email = _.get(this.props, 'match.params.email', '');

        const beVariable = window["__BE__"];

        const {i18n} = this.props;
        // Users from the PV website are either directly logged in or redirected to the SSO
        const directKeycloakLogin =  _.get(beVariable, 'ref') === "website";

        return (
            <div className="full-size login-background" style={{display: "flex", flexDirection: "column",}}>
                {/*<NotificationBanner showDetailsButton={false} />*/}

                <div className="full-size" style={{display: "flex", flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
                    <ShadowPanel topBar={<VSMColorBar/>} style={{width: 360}}>
                        <ReactKeycloakProvider
                            authClient={keycloak}
                            initOptions={{ onLoad: 'login-required' }}
                            LoadingComponent={<VSMLoadingAnimation/>}
                            isLoadingCheck={(keycloak) => !keycloak.authenticated}
                        >
                            {/*{*/}
                            {/*    !keycloak.token ?*/}
                            {/*        <NoAccessPanel*/}
                            {/*            role={this.state.userRole}*/}
                            {/*            onLogout={() => this.setState({userRole: null})}*/}
                            {/*        />*/}
                            {/*    :*/}
                            {/*        <Redirect to={_.get(search, 'redirect', '/')}/>*/}
                            {/*}*/}
                            <KeycloakLoginHandler/>
                        </ReactKeycloakProvider>
                    </ShadowPanel>
                </div>

                <div style={{alignSelf: 'center', height: 50}} className={"footer text-center"}>
                    <VersionLabel/>
                </div>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withTranslation()
)(LoginRoute);
