// @flow
import React, {Component} from "react"

import FontAwesomeIcon from "../Icons/FontAwesomeIcon";
import ClientIcon from "./ClientIcon/index.js";
import SiteIcon from "./SiteIcon";
import DepartmentIcon from "./DepartmentIcon";
import BoxIcon from "./BoxIcon";
import ChamberIcon from "./ChamberIcon";
import ContainerIcon from "./ContainerIcon";
import MachineIcon from "./MachineIcon";
import StationIcon from "./StationIcon";
import PoolIcon from "./PoolIcon";
import SemiconductorFabIcon from "./SemiconductorFabIcon";
import ShelfIcon from "./ShelfIcon";
import ServiceProviderIcon from "./ServiceProviderIcon";
import StockIcon from "./StockIcon";
import ToolIcon from "./ToolIcon";

type Props = {
    componentTypeIndex: string,
    size: number,
    color: string
}

class ComponentTypeIcon extends Component<Props> {

    render() {
        switch (this.props.componentTypeIndex) {
            case 'client':
                return <ClientIcon size={this.props.size} color={this.props.color}/>

            case 'serviceProvider':
                return <ServiceProviderIcon size={this.props.size} color={this.props.color}/>

            case 'site':
                return <SiteIcon size={this.props.size} color={this.props.color}/>

            case 'department':
                return <DepartmentIcon size={this.props.size} color={this.props.color}/>

            case 'semiconductorFab':
                return <SemiconductorFabIcon size={this.props.size} color={this.props.color}/>

            case 'chamber':
                return <ChamberIcon size={this.props.size} color={this.props.color}/>

            case 'tool':
                return <ToolIcon size={this.props.size} color={this.props.color}/>

            case 'machine':
                return <MachineIcon size={this.props.size} color={this.props.color}/>

            case 'station':
                return <StationIcon size={this.props.size} color={this.props.color}/>

            case 'container':
                return <ContainerIcon size={this.props.size} color={this.props.color}/>

            case 'stock':
                return <StockIcon size={this.props.size} color={this.props.color}/>

            case 'shelf':
                return <ShelfIcon size={this.props.size} color={this.props.color}/>

            case 'box':
                return <BoxIcon size={this.props.size} color={this.props.color}/>

            case 'pool':
                return <PoolIcon size={this.props.size} color={this.props.color}/>;

            case 'loanPool':
                return <PoolIcon size={this.props.size} color={this.props.color}/>;


            default:
                return <BoxIcon size={this.props.size} color={this.props.color}/>
        }
    }
}

export default ComponentTypeIcon;
