import React from 'react';

const SemiconductorFabIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path className="st0" d="M10,15h4c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-4c0-0.3-0.1-0.5-0.3-0.7C14.5,9.1,14.3,9,14,9h-4
		C9.7,9,9.5,9.1,9.3,9.3C9.1,9.5,9,9.7,9,10v4c0,0.3,0.1,0.5,0.3,0.7C9.5,14.9,9.7,15,10,15z M11,11h2v2h-2V11z"/>
        <path className="st0" d="M20,13h-1v-2h1c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7C20.5,9.1,20.3,9,20,9h-1V7
		c0-0.5-0.2-1-0.6-1.4S17.5,5,17,5h-2V4c0-0.3-0.1-0.5-0.3-0.7C14.5,3.1,14.3,3,14,3s-0.5,0.1-0.7,0.3C13.1,3.5,13,3.7,13,4v1h-2V4
		c0-0.3-0.1-0.5-0.3-0.7C10.5,3.1,10.3,3,10,3S9.5,3.1,9.3,3.3C9.1,3.5,9,3.7,9,4v1H7C6.5,5,6,5.2,5.6,5.6S5,6.5,5,7v2H4
		C3.7,9,3.5,9.1,3.3,9.3C3.1,9.5,3,9.7,3,10s0.1,0.5,0.3,0.7C3.5,10.9,3.7,11,4,11h1v2H4c-0.3,0-0.5,0.1-0.7,0.3
		C3.1,13.5,3,13.7,3,14s0.1,0.5,0.3,0.7C3.5,14.9,3.7,15,4,15h1v2c0,0.5,0.2,1,0.6,1.4S6.5,19,7,19h2v1c0,0.3,0.1,0.5,0.3,0.7
		C9.5,20.9,9.7,21,10,21s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-1h2v1c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3
		s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-1h2c0.5,0,1-0.2,1.4-0.6S19,17.5,19,17v-2h1c0.3,0,0.5-0.1,0.7-0.3
		c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7C20.5,13.1,20.3,13,20,13z M17,17H7V7h10V17z"/>
    </svg>
);

export default SemiconductorFabIcon;