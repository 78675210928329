// @flow
import React, {Component} from "react"
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {Col, Menu, Row} from "antd";
import MenuFactory from "../../utils/menu";
import ClientDashboardRoute from "./ClientDashboardRoute";
import UserDashboardRoute from "./UserDashboardRoute";
import UserListRoute from "./UserListRoute";
import RoleListRoute from "./RoleListRoute";
import ClientListRoute from "./ClientListRoute";
import CustomAttributeListRoute from "./CustomAttributeListRoute";
import CustomAttributeDashboardRoute from "./CustomAttributeDashboardRoute";
import RoleDashboardRoute from "./RoleDashboardRoute";
import UserCreate from "./UserCreate"
import RoleCreate from "./RoleCreate"
import ClientCreateRoute from "./ClientCreateRoute";
import NewsListRoute from "./NewsListRoute";
import NewNewsRoute from "./NewNewsRoute";
import LogisticianListRoute from "./LogisticianListRoute";
import NewLogistician from "./NewLogistician";
import LogisticianDashboardRoute from "./LogisticianDashboardRoute";
import NewsDashboardRoute from "./NewsDashboardRoute";
import ServiceProviderListRoute from "./ServiceProviderListRoute";
import ServiceProviderCreateRoute from "./ServiceProviderCreateRoute";
import ServiceProviderDashboardRoute from "./ServiceProviderDashboardRoute";
import ServiceCenterDashboardRoute from "./ServiceCenterDashboardRoute";
import CustomAttributeCreateRoute from "./CustomAttributeCreateRoute";
import ServiceCentersListRoute from "./ServiceCentersListRoute";
import ServiceCenterCreateRoute from "./ServiceCenterCreateRoute";
import ServiceDepartmentDashboardRoute from "./ServiceDepartmentDashboardRoute";
import _ from 'lodash';
import DeveloperSettingsRoute from "./DeveloperSettingsRoute";
import {compose} from "recompose";
import withProfile from "../../hoc/withProfile";
import CompanyDashboardRoute from "./CompanyDashboardRoute";
import TCORoute from "./TCORoute";
import HostsRoute from "./HostsRoute";
import HostDetailRoute from "./HostDetailRoute";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ServiceActionTypeDetailRoute from "./ServiceActionTypeDetailRoute";
import ServiceActionTypeListRoute from "./ServiceActionTypeListRoute";
import Error404 from "../errorPages/error404";
import {mdiBug, mdiCog, mdiViewList} from '@mdi/js';
import StatisticsRoute from "./StatisticsRoute";
import ClientAccessRequestDetailRoute from "./ClientAccessRequestDetailRoute";
import ClientAccessRequestListRoute from "./ClientAccessRequestListRoute";

type Props = {
    resources: any[],
    location: any,
    history: {
        push: Function,
    }
}


const menu = [
    {
        id: 'vsm',
        icon: 'cubes',
        name: 'Virtual Service Management',
        resources: ["newsEditor", "clientAccessRequest", "administrateCompany", "administrateClients", "accounting", "logistician"],
        items: [

            {
                id: '/admin/clients/search',
                route: '/admin/clients',
                resources: [],
                name: 'Clients',
                icon: 'face-agent'
            },
            {
                id: '/admin/accessrequests',
                route: '/admin/accessrequests',
                resources: ['clientAccessRequest'],
                name: 'Access requests',
                icon: 'lock'
            },
            {
                id: '/admin/company',
                route: '/admin/company',
                resources: ['administrateCompany'],
                name: 'Company',
                icon: 'domain'
            },
            {
                id: '/admin/news',
                route: '/admin/news',
                resources: ['newsEditor'],
                name: 'News',
                icon: 'newspaper'
            },
            {
                id: '/admin/logisticians',
                route: '/admin/logisticians',
                resources: ['logistician'],
                name: 'Logisticians',
                icon: 'truck'
            },
            {
                id: '/admin/statistics/dashboard',
                route: '/admin/statistics',
                resources: ['statistics'],
                name: 'Statistics',
                icon: 'chart-box'
            }
        ]
    },
    {
        id: 'admin-user-management',
        resources: ["administrateUsers", "administrateRoles"],
        name: 'User Management',
        icon: 'users',
        items: [
            {
                id: '/admin/users',
                resources: ['administrateUsers'],
                name: 'User',
                route: '/admin/users',
                icon: 'account'
            },
            {
                id: '/admin/roles',
                resources: ['administrateRoles'],
                name: 'Roles',
                route: '/admin/roles',
                icon: 'folder-account-outline'
            }
        ]
    },
    {
        id: 'admin-infrastructure',
        resources: ["administrateCustomAttributes", "administrateTCO"],
        name: 'Infrastructure',
        icon: 'cube',
        items: [
            {
                id: '/admin/attributes',
                resources: ['administrateCustomAttributes'],
                name: 'Custom Attributes',
                route: '/admin/attributes',
                icon: 'playlist-edit'
            },
            {
                id: '/admin/tco',
                resources: ['administrateTCO'],
                name: 'Total Cost of Ownership',
                route: '/admin/tco',
                icon: 'home-currency-usd'
            }
        ]
    },
    {
        id: 'admin-service',
        resources: ["administrateServiceProviders", "administrateServiceCenters", "administrateServiceActionTypePrivileges"],
        name: 'Service',
        icon: 'wrench',
        items: [
            {
                id: '/admin/serviceproviders',
                resources: ['administrateServiceProviders'],
                name: 'Service Provider',
                route: '/admin/serviceproviders',
                icon: 'face-agent'
            },
            {
                id: '/admin/servicecenters',
                resources: ['administrateServiceCenters'],
                name: 'Service Center',
                route: '/admin/servicecenters',
                icon: 'face-agent'
            },
            {
                id: '/admin/serviceactionstypes',
                resources: ['administrateServiceActionTypePrivileges'],
                name: 'Service Actions',
                route: '/admin/serviceactionstypes',
                icon: 'flash-auto'
            }
        ]
    },
    {
        id: 'admin-developer',
        resources: ["developer"],
        name: 'Developer',
        icon: mdiBug,
        items: [
            {
                id: '/admin/developer',
                name: 'Settings',
                resources: ["developer"],
                route: '/admin/developer',
                icon: mdiCog
            },
            {
                id: '/dev',
                name: 'Dev Route',
                resources: ["developer"],
                route: '/dev',
                icon: mdiViewList
            }
        ]
    }
]

class AdminRoute extends Component<Props> {

    render() {

        const {t, location, history, resources} = this.props;

        const menuItems = MenuFactory.getMenuItems(menu, t, null, resources);

        const openKeys = menuItems.map(item => item.key);
        let first = null;

        if (menuItems.length > 0 ) {
            first = _.get(menuItems,`[0].props.children[0].props.children.props.to`)
        }

        console.log(menuItems, 'pathname')

        // const menuItemRouteMatch = menuItems.find(menuItem => menuItem.props?.children?.some(item => item.key === location.pathname));
        const menuItemsFlattened = menuItems
            .map((menuItem) => menuItem.props.children)
            .flat()
        const menuItemRouteMatch = menuItemsFlattened.find((item) => item.key === location.pathname);
        const defaultSelectedKey = _.get(menuItemRouteMatch, 'key') || _.get(_.head(menuItemsFlattened), 'key')

        return <Row className={"calculatedBody"}>
            <Col className="full-height overflow-scroll" sm={10} md={8} lg={6} xl={5} xxl={4}>
                <Menu
                    className="vertical-menu vertical-menu-lg full-height"
                    mode="inline"
                    inlineIndent={8}
                    defaultSelectedKeys={defaultSelectedKey}
                    openKeys={openKeys}
                >
                    {menuItems}
                </Menu>
            </Col>
            <Col className="full-height overflow-scroll" sm={14} md={16} lg={18} xl={19} xxl={20}>
                <Switch>

                    <Route exact path="/admin/clients/new" component={ClientCreateRoute}/>
                    <Route exact path="/admin/clients/search" component={ClientListRoute}/>
                    <Redirect exact from="/admin/clients/:id" to={'/admin/clients/:id/form'}/>
                    <Redirect exact from="/admin/clients" to={'/admin/clients/search'}/>
                    <Route path="/admin/clients/:id/:tab" component={ClientDashboardRoute}/>

                    <Route path="/admin/accessrequests/:id" component={ClientAccessRequestDetailRoute}/>
                    <Route path="/admin/accessrequests" component={ClientAccessRequestListRoute}/>

                    <Route exact path="/admin/hosts" component={HostsRoute}/>
                    <Route path="/admin/hosts/:id/:tab" component={HostDetailRoute}/>
                    <Redirect exact from="/admin/hosts/:id" to={'/admin/hosts/:id/form'}/>

                    <Route exact path="/admin/company" component={CompanyDashboardRoute}/>

                    <Route path="/admin/news/new" component={NewNewsRoute}/>
                    <Route path="/admin/news/:id" component={NewsDashboardRoute}/>
                    <Route path="/admin/news" component={NewsListRoute}/>

                    <Route path="/admin/logisticians/new" component={() => <NewLogistician
                        onSubmit={(res) => {
                            const id = _.get(res, 'data.createLogistician.id')
                            if (id) history.push(`/admin/logisticians/${id}`)
                        }}/>}/>
                    <Route path="/admin/logisticians/detail/:id" component={LogisticianDashboardRoute}/>
                    <Route path="/admin/logisticians" component={LogisticianListRoute}/>

                    <Route path="/admin/statistics/:tab" component={StatisticsRoute}/>
                    <Redirect exact from="/admin/statistics" to={'/admin/statistics/dashboard'}/>

                    <Route exact path="/admin/users/add" component={UserCreate}/>
                    <Route exact path="/admin/users/detail/:id" component={UserDashboardRoute}/>
                    <Route exact path="/admin/users" component={UserListRoute}/>

                    <Route exact path="/admin/roles/add" component={RoleCreate}/>
                    <Route exact path="/admin/roles/:id" component={RoleDashboardRoute}/>
                    <Route exact path="/admin/roles" component={RoleListRoute}/>
                    <Route exact path="/admin/tco" component={TCORoute}/>

                    <Route path="/admin/attributes/new" component={CustomAttributeCreateRoute}/>
                    <Route path="/admin/attributes/:index" component={CustomAttributeDashboardRoute}/>
                    <Route path="/admin/attributes" component={CustomAttributeListRoute}/>

                    <Route path="/admin/serviceproviders/new" component={ServiceProviderCreateRoute}/>
                    <Route path="/admin/serviceproviders/:id" component={ServiceProviderDashboardRoute}/>
                    <Route path="/admin/serviceproviders" component={ServiceProviderListRoute}/>

                    <Route path="/admin/serviceactionstypes/:index" component={ServiceActionTypeDetailRoute}/>
                    <Route path="/admin/serviceactionstypes" component={ServiceActionTypeListRoute}/>


                    <Route path="/admin/servicecenters/new" component={ServiceCenterCreateRoute}/>
                    <Route path="/admin/servicecenters/:id/servicedepartments/:serviceDepartmentId"
                           component={ServiceDepartmentDashboardRoute}/>
                    <Route path="/admin/servicecenters/:id" component={ServiceCenterDashboardRoute}/>
                    <Route path="/admin/servicecenters" component={ServiceCentersListRoute}/>

                    <Route path="/admin/developer" component={DeveloperSettingsRoute}/>

                    {first ? <Redirect exact from="/admin" to={first}/> : null}


                    <Route path="/admin/:any" component={Error404}/>
                </Switch>

            </Col>
        </Row>
    }

}

export default compose(
    withRouter,
    withProfile(),
    waitWhileLoading('profileQuery'),
    withTranslation()
)(AdminRoute);
