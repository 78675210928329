// @flow
import React, {Component} from 'react';
import queue from "async/queue";
import BulkProgress from "../BulkProgress";
import _ from "lodash";
import {toastError} from "../../utils/toast";

type Props = {
    total: number,
    mutation: () => Promise,
    onFinished?: () => void,
};

type State = {

}

class MultiActionWrapper extends Component<Props, State> {

    state = {
        errors: [],
        loading: false,
        finishedCount: 0
    }

    constructor(props: Props) {
        super(props);

        const {mutation} = props;

        this.queue = queue((mutationOptions, callback) => {

            this.setLoading(true)
                .then( () => mutation(mutationOptions))
                .catch((err) => {
                    this.setState({
                        errors: [...this.state.errors, err]
                    })
                })
                .finally(() => {
                    this.setState({
                        finishedCount: this.state.finishedCount + 1
                    }, () => {
                        callback();
                    });

                })


        }, 1);


        // assign a callback
        this.queue.drain = () => {

            if (this.state.errors && this.state.errors.length){
                toastError(_.first(this.state.errors));
            }

            setTimeout(() => {
                this.setState({
                    loading: false,
                    finishedCount: 0,
                    errors: []
                });

                if (this.props.onFinished) {
                    this.props.onFinished();
                }
            },2000)
        };
    }

    setLoading = (loading) => {
        return new Promise((resolve) => {
            this.setState({
                loading
            }, resolve)
        })
    };


    render() {
    const { total, children } = this.props;

    return <div>
        {this.state.loading ? <BulkProgress total={total} finished={this.state.finishedCount}/> : children({
            queue: this.queue,
        })}
    </div>;
  }
}

export default MultiActionWrapper;
