// @flow
import React, {Component} from "react"
import {withTranslation} from "react-i18next";
import {Button} from "@dreebit/react-components";
import {Link, withRouter} from "react-router-dom";
import _ from 'lodash';

import ContentHeader from "../../components/Typo/ContentHeader";
import UserList from "./../../components/UserList";
import {compose} from "redux";
import AttributesInlineForm from "../../components/Form/attributes-inline-form";
import {withProps} from "recompose";
import queryString from "query-string";
import NavigationContainer from "../../components/NavigationContainer";
import withResources from "../../hoc/withResources";

type Props = {
    t: Function,
    history: {
        push: Function,
    }
};

class UserListRoute extends Component<Props> {
    render() {
        const {t, history, filter} = this.props;

        const attributes = [{
            index: "query",
            type: "string",
            inputType: "text",
            inputTypeOptions: {
                placeholder: t("Search")
            }
        }];

        return <NavigationContainer navigationBar={
            <ContentHeader title={t('Users')} tools={
                <Link to={'/admin/users/add'}>
                    <Button icon={"plus"}>
                        {t('Add')}
                    </Button>
                </Link>
            }/>
        }>
            <NavigationContainer navigationBar={
                <AttributesInlineForm
                    className={"ph-5 pt-5"}
                    attributes={attributes}
                    layout={'vertical'}
                    useSubmit={false}
                    onChange={(attributeValues) => {
                        history.push(`/admin/users?${queryString.stringify(attributeValues)}`)
                    }}
                    values={filter}
                />
            } >
                <UserList onClick={(record) => history.push(`/admin/users/detail/${record.id}`)}
                          filter={filter}
                />
            </NavigationContainer>
        </NavigationContainer>
    }
}

export default compose(
    withRouter,
    withTranslation(),
    withProps((props) => {
        const query = _.get(props, 'history.location.search');
        return {
            filter: queryString.parse(query),
        }
    })
    //withResources(["administrateUsers"])
)(UserListRoute);
