import Keycloak from "keycloak-js";

const host = window["__HOST__"];

const keycloak = new Keycloak({
    url: "https://testing-sso.vsm-cloud.com/auth",
    realm: "dev",
    clientId: host === "xenon" ? "vsm-xenon" : "vsm-pv",
});

export default keycloak;
