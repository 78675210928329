import React from 'react';

const ContainerIcon = ({ color, size }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <g>
            <path className="st0" d="M21.4,5.6C21,5.2,20.5,5,20,5H4C3.5,5,3,5.2,2.6,5.6C2.2,6,2,6.5,2,7v10c0,0.5,0.2,1,0.6,1.4
		C3,18.8,3.5,19,4,19h16c0.5,0,1-0.2,1.4-0.6C21.8,18,22,17.5,22,17V7C22,6.5,21.8,6,21.4,5.6z M20,17H4V7h16V17z"/>
            <path className="st0" d="M6,16c0.3,0,0.5-0.1,0.7-0.3C6.9,15.5,7,15.3,7,15V9c0-0.3-0.1-0.5-0.3-0.7C6.5,8.1,6.3,8,6,8S5.5,8.1,5.3,8.3
		C5.1,8.5,5,8.7,5,9v6c0,0.3,0.1,0.5,0.3,0.7C5.5,15.9,5.7,16,6,16z"/>
            <path className="st0" d="M9,16c0.3,0,0.5-0.1,0.7-0.3C9.9,15.5,10,15.3,10,15V9c0-0.3-0.1-0.5-0.3-0.7C9.5,8.1,9.3,8,9,8
		S8.5,8.1,8.3,8.3C8.1,8.5,8,8.7,8,9v6c0,0.3,0.1,0.5,0.3,0.7C8.5,15.9,8.7,16,9,16z"/>
            <path className="st0" d="M12,16c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-2.5V9c0-0.3-0.1-0.5-0.3-0.7C12.5,8.1,12.3,8,12,8
		s-0.5,0.1-0.7,0.3C11.1,8.5,11,8.7,11,9v6c0,0.3,0.1,0.5,0.3,0.7C11.5,15.9,11.7,16,12,16z"/>
            <path className="st0" d="M15,16c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7V9c0-0.3-0.1-0.5-0.3-0.7C15.5,8.1,15.3,8,15,8
		s-0.5,0.1-0.7,0.3C14.1,8.5,14,8.7,14,9v6c0,0.3,0.1,0.5,0.3,0.7C14.5,15.9,14.7,16,15,16z"/>
            <path className="st0" d="M18,16c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7V9c0-0.3-0.1-0.5-0.3-0.7C18.5,8.1,18.3,8,18,8
		s-0.5,0.1-0.7,0.3C17.1,8.5,17,8.7,17,9v6c0,0.3,0.1,0.5,0.3,0.7C17.5,15.9,17.7,16,18,16z"/>
        </g>
    </svg>
);

export default ContainerIcon;