import React from 'react';

const ShelfIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path className="st0" d="M20.7,1.3C20.5,1.1,20.3,1,20,1s-0.5,0.1-0.7,0.3C19.1,1.5,19,1.7,19,2v1H5V2c0-0.3-0.1-0.5-0.3-0.7
	C4.5,1.1,4.3,1,4,1S3.5,1.1,3.3,1.3C3.1,1.5,3,1.7,3,2v20c0,0.3,0.1,0.5,0.3,0.7C3.5,22.9,3.7,23,4,23s0.5-0.1,0.7-0.3
	C4.9,22.5,5,22.3,5,22v-1h14v1c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7V2
	C21,1.7,20.9,1.5,20.7,1.3z M15,19h-2v-2h2V19z M19,19h-2v-3c0-0.3-0.1-0.5-0.3-0.7C16.5,15.1,16.3,15,16,15h-4
	c-0.3,0-0.5,0.1-0.7,0.3C11.1,15.5,11,15.7,11,16v3H5v-6h14V19z M9,11V9h2v2H9z M19,11h-6V8c0-0.3-0.1-0.5-0.3-0.7
	C12.5,7.1,12.3,7,12,7H8C7.7,7,7.5,7.1,7.3,7.3C7.1,7.5,7,7.7,7,8v3H5V5h14V11z"/>
    </svg>
);

export default ShelfIcon;