import {ApolloProvider} from "@apollo/client";
import React, {Component} from "react";
import getClient from "../store/apollo";
import {compose} from "recompose";
import {connect} from "react-redux";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import keycloak from "../keycloak";

type Props = {

};

type State = {

}

class GraphQLProvider extends Component<Props, State> {

    render() {

        const {language, dispatch, i18n} = this.props;
        const token = keycloak.token
        const client = getClient(token, language, dispatch, i18n);

        return <ApolloProvider client={client}>
            {this.props.children}
        </ApolloProvider>
    }
}


export default compose(
    connect((state) => ({
        language: _.get(state, 'settings.language')
    })),
    withTranslation(),
)(GraphQLProvider);
