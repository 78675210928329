// @flow
import React, {Component} from "react"
import _ from 'lodash'
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {Spin} from "antd";
import AddOpenedServiceTicketMutation from "./../../graphql/mutations/addOpenedServiceTicket.graphql";
import ServiceTicketNameQuery from "./../../graphql/queries/serviceTicket-Name.graphql";
import {withRouter} from "react-router-dom";
import {toastError} from "../../utils/toast";
import openedServiceTicketList from "../../graphql/queries/openedServiceTicketList.graphql";
import OneLineLabel from "../OneLineLabel";
import {ApolloError} from "@apollo/client";

type Props = {
    addOpenedServiceTicketMutation: Function,
    serviceTicketNameQuery: any,
    serviceTicketId: string,
}

class AddOpenedServiceTicketTab extends Component<Props> {

    state = {
        loading: true,
        title: undefined
    };

    componentDidMount() {

        const {addOpenedServiceTicketMutation} = this.props;
        addOpenedServiceTicketMutation().then(() => {

        }).catch((err: ApolloError) => {
            const entityNotFoundError = _.find(err.graphQLErrors, (error) => {
                return _.get(error, 'extensions.category') === 'EntityNotFound';
            });

            if (!entityNotFoundError) {
                toastError(err);
            }
        }).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    render() {
        const {loading} = this.state;
        const {serviceTicketNameQuery} = this.props;

        return <span><OneLineLabel
            style={{maxWidth: '220px'}}>{_.get(serviceTicketNameQuery, 'serviceTicket.name')} {loading || serviceTicketNameQuery.loading ?
            <Spin size={'small'}/> : null} </OneLineLabel></span>
    }

}

export default compose(
    graphql(AddOpenedServiceTicketMutation, {
        name: 'addOpenedServiceTicketMutation',
        options: (props: Props) => ({
            refetchQueries: [{
                query: openedServiceTicketList
            }],
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
    graphql(ServiceTicketNameQuery, {
        name: 'serviceTicketNameQuery',
        options: (props: Props) => ({
            variables: {
                id: props.serviceTicketId
            }
        })
    }),
)(withRouter(AddOpenedServiceTicketTab));
