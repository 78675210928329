export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_UUID = 'USER_UUID';

export const MENU_TOGGLE = 'MENU_TOGGLE';
export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSED = 'MENU_CLOSED';

export const SAVE_FORM = 'SAVE_FORM';
export const SET_PROFILE_RESOURCES = 'SET_PROFILE_RESOURCES';
export const SET_PROFILE_LANGUAGE = 'SET_PROFILE_LANGUAGE';
export const SET_COMPONENT_BROWSER_COMPONENT = 'SET_COMPONENT_BROWSER_COMPONENT';
export const SET_COMPONENT_BROWSER_CLIENT_LIST = 'SET_COMPONENT_BROWSER_CLIENT_LIST';
export const SET_COMPONENT_BROWSER_STOCK_SEARCH = 'SET_COMPONENT_BROWSER_STOCK_SEARCH';
export const SET_LAST_INFRASTRUCTURE_SEARCH = 'SET_LAST_INFRASTRUCTURE_SEARCH';
export const SET_COMPONENT_BROWSER_WIDTH = 'SET_COMPONENT_BROWSER_WIDTH';
export const SET_LAST_SERVICE_SEARCH = 'SET_LAST_SERVICE_SEARCH';
export const SET_LAST_PRODUCT_SEARCH = 'SET_LAST_PRODUCT_SEARCH';
export const SET_LAST_MANUFACTURER_SEARCH = 'SET_LAST_MANUFACTURER_SEARCH';
export const SET_LAST_WORKFLOW_SEARCH = 'SET_LAST_WORKFLOW_SEARCH';
export const SET_LAST_BUDGET_SEARCH = 'SET_LAST_BUDGET_SEARCH';
export const SET_ACTIVE_SERVICE_TAB = 'SET_ACTIVE_SERVICE_TAB';
export const SET_ACTIVE_INFRASTRUCTURE_TAB = 'SET_ACTIVE_INFRASTRUCTURE_TAB';
export const SET_FILE_TABLE_VIEW = 'SET_FILE_TABLE_VIEW';
