import React from 'react';

const SiteIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <g>
            <rect x="15" y="14" width="2" height="4"/>
            <rect x="7" y="14" width="2" height="4"/>
            <rect x="11" y="14" width="2" height="4"/>
            <path d="M21.1,2.9c0-0.2-0.1-0.5-0.3-0.6C20.6,2.1,20.4,2,20.1,2h-1.2c-0.2,0-0.5,0.1-0.7,0.2
		c-0.2,0.2-0.3,0.4-0.3,0.6L17,10h-3V8.5c0-0.3-0.1-0.6-0.4-0.8c-0.3-0.2-0.6-0.2-0.9-0.1L9,9V8.5c0-0.4-0.2-0.6-0.5-0.8
		s-0.6-0.2-1-0.1L3.2,9.5c-0.4,0.2-0.7,0.4-0.9,0.8C2.1,10.5,2,10.9,2,11.3V20c0,0.5,0.2,1,0.6,1.4S3.5,22,4,22h16
		c0.5,0,1-0.2,1.4-0.6S22,20.5,22,20V10L21.1,2.9z M20,20H4v-8.7L7,10v2l5-2v2h8V20z"/>
        </g>
    </svg>
);

export default SiteIcon;