import React from 'react';

const ToolIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path className="st0" d="M17.3,10.2l1.4-1.4l2.1,2.1c0.3-0.3,0.5-0.6,0.7-1c0.1-0.4,0.2-0.8,0.2-1.2c0-0.4-0.1-0.8-0.2-1.1
		c-0.2-0.4-0.4-0.7-0.7-1l-3.5-3.5l-1.4,1.4V1.7L15.2,1l-3.5,3.5l0.7,0.7h2.8l-1.4,1.4l1.1,1.1L12,10.6L7.8,6.5V5.1l-3-3L2,4.9l3,3
		h1.4l4.1,4.1l-0.9,0.9H7.6l-5.3,5.3c-0.1,0.1-0.2,0.2-0.2,0.3C2,18.6,2,18.7,2,18.9c0,0.1,0,0.3,0.1,0.4s0.1,0.2,0.2,0.3l2.1,2.1
		c0.1,0.1,0.2,0.2,0.3,0.2S5,22,5.1,22s0.3,0,0.4-0.1s0.2-0.1,0.3-0.2l5.3-5.3v-2.1l5.2-5.2L17.3,10.2z M9.4,15.3l-4.2,4.2l-0.3-0.4
		l-0.4-0.3l4.2-4.2h0.7V15.3z"/>
        <path className="st0" d="M21.9,18.5c0-0.1-0.1-0.2-0.2-0.3l-5.1-5.1c-0.1-0.1-0.2-0.2-0.3-0.2s-0.2-0.1-0.4-0.1s-0.3,0-0.4,0.1
		s-0.2,0.1-0.3,0.2L13,15.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3l5.1,5.1
		c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2l2.1-2.1c0.1-0.1,0.2-0.2,0.2-0.3
		c0-0.1,0.1-0.2,0.1-0.4C22,18.7,21.9,18.6,21.9,18.5z M18.8,19.6l-3.7-3.7l0.7-0.7l3.7,3.7L18.8,19.6z"/>
    </svg>
);

export default ToolIcon;