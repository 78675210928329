// @flow
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc';
import {compose, withProps} from "recompose";
import _ from "lodash";
import ComponentImageQuery from "./../../graphql/queries/componentImage.graphql";
import {Avatar, Modal} from "antd";
import ComponentImageUploader from "../ComponentImageUploader";
import {withTranslation} from "react-i18next";
import defaultComponentImage from "./images/component-image-default.png"

type Props = {
    componentId?: string,
    link?: string,
    size?: number,
    componentImageQuery?: any,
    crop?: "crop" | "fit" | "scale",
    editable?: boolean,
};

type State = {
    showModal: boolean,
}

class ComponentImage extends Component<Props, State> {

    static defaultProps = {
        size: 90
    }

    state = {
        showModal: false,
    }

    setModalVisibility = (visible: boolean) => {
        this.setState({showModal: visible})
    }

    render() {

        const {t, componentImageQuery} = this.props;

        if (!this.props.editable) {
            return this.renderImage();
        }

        return (
            <div className={"link"}>
                {this.renderImage()}
                <Modal
                    visible={this.state.showModal}
                    destroyOnClose
                    footer={null}
                    onCancel={() => this.setModalVisibility(false)}
                    onOk={() => this.setModalVisibility(false)}
                    title={t("Upload a new component image")}
                >
                    <ComponentImageUploader
                        onFinished={() => {
                            componentImageQuery.refetch();
                            this.setModalVisibility(false)
                        }}
                        componentId={this.props.componentId}/>
                </Modal>
            </div>
        );
    }

    renderImage() {
        let {link} = this.props;

        // if (link && link.endsWith("default-image-component.png")) {
        //     link = null;
        // }

        if (!link) {
            link = defaultComponentImage;
        }

        return <Avatar
            onClick={this.props.editable ? () => this.setModalVisibility(true) : undefined}
            shape={'square'} /* icon={"code-sandbox"} */
            size={this.props.size}
            src={link}
        />
    }
}
export default  compose(
    graphql(ComponentImageQuery, {
        name: 'componentImageQuery',
        skip: props => {
            // check if the ID string contains only numbers - so we don't send the request if it's a "fake" component like "strc-24"
            const regex = new RegExp(`^[0-9]*$`);
            return !props.componentId || !regex.test(props.componentId)
        },
        options: (props: Props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.componentId,
                width: props.size || ComponentImage.defaultProps.size,
                height: props.size || ComponentImage.defaultProps.size,
                crop: props.crop || 'crop',
            }
        })
    }),
    withProps((props) => ({
        link: props.link || _.get(props, 'componentImageQuery.component.image.thumbnailLink')
    })),
    withTranslation(),
)(ComponentImage);
