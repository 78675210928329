// @flow
import React, {useEffect} from 'react';
import {Redirect} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import KeycloakService from "../../service/keycloak";
import {logout} from "../../actions/auth";
import VSMLoadingAnimation from "../../components/VSMLoadingAnimation";
import _ from "lodash";
import keycloak from "../../keycloak";

interface Props {

}

const LogoutRoute = ({}: Props) => {

    const dispatch = useDispatch();

    const token =  useSelector((state) => {
        return _.get(state, 'auth.token')
    })

    console.log("token",token);

    useEffect(() => {
        keycloak.logout().then((res) => {
            console.log(res);
            dispatch(logout())
        })
    },[])

    if (!keycloak.token){
        return <Redirect to={"/login"} />;
    }
    return <VSMLoadingAnimation />;
}

export default LogoutRoute;


