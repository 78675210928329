// @flow
import React, {Component} from 'react';
import TDAAttributesForm from "../TDAAttributesForm";
import {withTranslation} from "react-i18next";
import {compose} from "recompose";
import _ from "lodash";
import {graphql} from "@apollo/client/react/hoc";
import createBookmark from "../../graphql/mutations/createBookmark.graphql";
import updateBookmark from "../../graphql/mutations/updateBookmark.graphql";
import bookmarkList from "../../graphql/queries/bookmarkList.graphql";
import {Form, Select} from "antd";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import BookmarkListQuery from "./../../graphql/queries/bookmarkList.graphql"
import {toastSuccess} from "../../utils/toast";

const FormItem = Form.Item;
const { Option } = Select;

type Props = {
    serviceTicketIds: string[],
    onCreated: () => void,
    bookmarkListQuery?: any
};

type State = {
    selection: string
}

class ServiceTicketsCreateSelectionBookmark extends Component<Props, State> {

    state = {
        selection: 'new'
    };

  render() {
    const { createBookmarkMutation, t, serviceTicketIds, onCreated, updateBookmarkMutation, bookmarkListQuery } = this.props;
    const {selection} = this.state;


    return <div className={'flex-column'}>
        <FormItem style={{marginBottom: 16}} label={t('Action')} labelCol={{span: 8}} wrapperCol={{span: 16}}>
            <Select
                onSelect={(val: any) => {
                    this.setState({selection: val});
                }}
                size={'middle'}
                defaultValue={selection}
                style={{width: '100%'}}
            >
                <Option value="new">{t('Create new Bookmark')}</Option>
                <Option value="add">{t('Add Selection to existing Stack')}</Option>
            </Select>
        </FormItem>
        {
            selection === 'new'
                ? <TDAAttributesForm
                    attributes={[
                        {
                            index: "name",
                            inputType: 'text',
                            name: t("Name")
                        }
                    ]}
                    mutation={(args) => {
                        const values = args.variables;
                        createBookmarkMutation({
                            variables: {
                                input: {
                                    title: values.name,
                                    type: 'serviceTicketSelection',
                                    data: JSON.stringify({

                                    })
                                }
                            }
                        })
                            .then((res) => {
                                const id = _.get(res,'data.createBookmark.id');
                                return updateBookmarkMutation({
                                    variables: {
                                        id,
                                        input: {
                                            data: JSON.stringify({
                                                serviceTicketIds,
                                                link: `/service/search?selectionBookmark=${id}`
                                            })
                                        }
                                    }
                                })
                            })
                            .then((res) => {
                                if (onCreated) onCreated(res);
                            })
                    }}
                />
                : null
        }
        {
            selection === 'add'
                ? <FormItem style={{marginBottom: 16}} label={t('Bookmark')} labelCol={{span: 8}} wrapperCol={{span: 16}}>
                    <Select
                        ref={this.selectRef}
                        onSelect={(val: any) => {
                            const bookmark = _.find(bookmarkListQuery.bookmarkList.bookmarks, {id: val});
                            const data = JSON.parse(_.get(bookmark, 'data', ''));

                            let bookmarkServiceTicketIds = [];
                            if(data && data.serviceTicketIds){
                                bookmarkServiceTicketIds = data.serviceTicketIds
                            }

                            const Ids = _.uniq([
                                ...bookmarkServiceTicketIds,
                                ...serviceTicketIds
                            ]);

                            updateBookmarkMutation({
                                variables: {
                                    id: val,
                                    input: {
                                        data: JSON.stringify({
                                            serviceTicketIds: Ids,
                                            link: `/service/search?selectionBookmark=${val}`
                                        })
                                    }
                                }
                            })
                                .then((res) => {
                                    toastSuccess(t('Successfully added'));
                                    if (onCreated) onCreated(res);
                                })
                                .catch(() => {
                                    toastSuccess(t('Fail to add selection to Bookmark'));
                                })
                        }}
                        showSearch
                        filterOption={(input, option) => {
                            return (
                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        defaultOpen={true}
                        autoFocus={true}
                        size={'middle'}
                        placeholder={t('Bookmark')}
                        style={{width: '100%'}}>
                        {bookmarkListQuery.bookmarkList.bookmarks.map((item: any) => (
                            <Select.Option key={item.id} value={item.id}
                                           title={item.title}>
                                <span>{item.title}</span>
                            </Select.Option>
                        ))}
                    </Select>
                </FormItem>
                : null
        }
    </div>
  }
}

export default compose(
    withTranslation(),
    graphql(BookmarkListQuery, {
        name: 'bookmarkListQuery',
        options: props => ({
            fetchPolicy: 'network-only',
            variables: {
                params: {
                    filter: {
                        type: {
                            value: 'serviceTicketSelection'
                        }
                    }
                }
            }
        }),
    }),
    waitWhileLoading('bookmarkListQuery', 'bookmarkList.bookmarks'),
    graphql(createBookmark, {
        name: 'createBookmarkMutation'
    }),
    graphql(updateBookmark, {
        name: 'updateBookmarkMutation',
        options: () => ({
            refetchQueries: [{
                query: bookmarkList,
                variables: {
                    params: {
                        filter: {
                            type: {
                                value: ['serviceTicketSearch','serviceTicketSelection']
                            }
                        },
                        start: 0,
                        limit: 50
                    }
                }
            }]
        })
    }),
)(ServiceTicketsCreateSelectionBookmark);
