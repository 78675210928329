import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from 'lodash';
import {compose} from "recompose";
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import AppWrapper from "../../routes/appWrapper";
import Login from "../../routes/login";
import LogoutRoute from "../../routes/logout";
import UserProvider from "../../provider/user";
import SocketProviderWrapper from "../../provider/socket";
import CacheReset from "../CacheReset";
import KpiRoute from "../../routes/kpi";
import DecoRoute from "../../routes/rma/DecoRoute";
import DecoSuccessRoute from "../../routes/rma/DecoSuccessRoute";
import InfrastructureCreateTokenLandingPage
    from "../../routes/InfrastructureCreateComponent/InfrastructureCreateTokenLandingPage";
import ErrorPagesRoute from "../../routes/errorPages";
import WorkflowboardRoute from "../../routes/workflowboard";
import Dev from "../../routes/dev";


class App extends Component {

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {

        return nextProps.token !== this.props.token;
    }

    render() {

        const {token, dispatch} = this.props;

        if (window.location.pathname.includes("/rma-deco-success")){
            return  <Router>
                <Route path="/" component={DecoSuccessRoute}/>
            </Router>
        }

        if (window.location.pathname.includes("/rma-deco")){
            return <Router>
                <Route path="/" component={DecoRoute}/>
            </Router>
        }

        const router = <Router>
            <Switch>
                <Route path="/error" component={ErrorPagesRoute}/>
                <Route path="/login/:email" component={Login}/>
                <Route path="/login" component={Login}/>
                <Route exact path="/logout" component={LogoutRoute}/>
                {!token ?
                    <Route from="/infrastructure/cmt/:token" component={InfrastructureCreateTokenLandingPage}/> : null}
                <Route path="/kpi" component={KpiRoute}/>
                <Route path="/dev" component={Dev}/>
                <Route path="/workflowboard" component={WorkflowboardRoute}/>
                <Route path="/deco" component={DecoRoute}/>
                <Route path="/deco-success" component={DecoSuccessRoute}/>
                <Route path="/" component={() => <AppWrapper/>}/>
            </Switch>

        </Router>;

        if (!token) {
            return router;
        }

        return <UserProvider>
            <SocketProviderWrapper>
                <CacheReset>
                    {router}
                </CacheReset>
            </SocketProviderWrapper>
        </UserProvider>

    }
}


export default compose(
    connect((state) => ({
        token: _.get(state, 'auth.token'),
    }))
)(App);
