import React from 'react';

const ServiceProviderIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path className="st0" d="M19.7,16.1l-5.1-5c0.1-0.3,0.2-0.7,0.3-1C15,9.7,15,9.4,15,9c0-1.7-0.6-3.1-1.8-4.2C12.1,3.6,10.7,3,9,3
	C8.7,3,8.3,3,8,3.1c-0.3,0-0.7,0.1-1,0.3C6.9,3.4,6.8,3.5,6.7,3.7C6.6,3.8,6.5,3.9,6.5,4.1c0,0.1,0,0.3,0,0.5c0,0.2,0.1,0.3,0.3,0.4
	l2.6,2.6L7.6,9.4L5,6.8C4.8,6.6,4.7,6.6,4.5,6.5c-0.2,0-0.3,0-0.5,0c-0.2,0-0.3,0.1-0.4,0.2S3.4,6.9,3.3,7C3.2,7.3,3.1,7.7,3.1,8
	C3,8.3,3,8.7,3,9c0,1.7,0.6,3.1,1.8,4.2C5.9,14.4,7.3,15,9,15c0.4,0,0.7,0,1.1-0.1c0.3-0.1,0.7-0.2,1-0.3l5,5
	c0.5,0.5,1.1,0.8,1.8,0.8s1.3-0.2,1.8-0.7s0.7-1.1,0.7-1.8S20.1,16.6,19.7,16.1z M18.2,18.2c-0.1,0.1-0.2,0.1-0.3,0.1
	c-0.1,0-0.3,0-0.3-0.1l-6.1-6.1c-0.3,0.3-0.7,0.5-1.2,0.6C9.9,12.9,9.4,13,9,13c-1,0-1.9-0.3-2.6-1C5.6,11.4,5.2,10.6,5,9.6l1.9,1.9
	c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3.2-3.2c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7L9.7,5c0.9,0.2,1.7,0.6,2.4,1.3
	C12.7,7.1,13,8,13,9c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.4-0.3,0.8-0.6,1.2l6.1,6c0.1,0.1,0.1,0.2,0.1,0.3C18.3,18,18.3,18.1,18.2,18.2z"
        />
    </svg>
);

export default ServiceProviderIcon;