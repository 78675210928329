import React from 'react';

const StationIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path className="st0" d="M22,9.7l-1.4-5c-0.1-0.2-0.2-0.4-0.4-0.5C20,4.1,19.8,4,19.6,4H4.4C4.2,4,4,4.1,3.8,4.2
	C3.7,4.3,3.5,4.5,3.5,4.7L2,9.7C2,10,2,10.3,2.2,10.6C2.4,10.9,2.7,11,3,11h2.2l-1.1,7.9c0,0.3,0,0.6,0.2,0.8
	C4.6,19.9,4.8,20,5.1,20c0.2,0,0.5-0.1,0.7-0.2c0.2-0.2,0.3-0.4,0.3-0.6L6.7,15h10.7l0.5,4.1c0,0.2,0.1,0.5,0.3,0.6
	c0.2,0.2,0.4,0.2,0.7,0.2c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.2-0.8l-1-7.9H21c0.3,0,0.6-0.1,0.8-0.4C22,10.3,22,10,22,9.7z
	 M7,13l0.3-2h9.6l0.2,2H7z M4.3,9l0.9-3h13.6l0.8,3H4.3z"/>
    </svg>
);

export default StationIcon;