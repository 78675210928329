import React from 'react';

const MachineIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
            <path d="M10.6,10.1c0.2-0.1,0.4-0.2,0.5-0.4l0.2-0.4c0.1-0.2,0.2-0.4,0.1-0.6c0-0.2-0.1-0.4-0.3-0.6l-0.5-0.5
		c0-0.2,0-0.4,0-0.6s0-0.4,0-0.7l0.5-0.5c0.2-0.2,0.3-0.3,0.3-0.6s0-0.5-0.1-0.7l-0.2-0.3C11,4.1,10.8,4,10.6,3.9
		c-0.2-0.1-0.4-0.1-0.6,0L9.2,4.1C9.1,4,8.9,3.9,8.7,3.8C8.5,3.7,8.3,3.6,8.2,3.5L8,2.8c0-0.2-0.2-0.4-0.3-0.6C7.5,2.1,7.3,2,7,2
		H6.7C6.4,2,6.2,2.1,6,2.2C5.9,2.4,5.8,2.5,5.7,2.8L5.5,3.5C5.3,3.6,5.2,3.7,5,3.8C4.8,3.9,4.6,4,4.5,4.1L3.7,3.9
		c-0.2-0.1-0.4-0.1-0.6,0C2.9,4,2.7,4.1,2.6,4.3L2.4,4.7C2.3,4.9,2.2,5.1,2.3,5.3c0,0.2,0.1,0.4,0.3,0.6l0.6,0.5
		c0,0.2-0.1,0.4-0.1,0.7s0,0.4,0.1,0.6L2.6,8.1C2.4,8.3,2.3,8.5,2.3,8.7c0,0.2,0,0.4,0.1,0.6l0.2,0.4c0.1,0.2,0.3,0.3,0.5,0.4
		c0.2,0.1,0.4,0.1,0.6,0l0.7-0.2C4.6,10,4.8,10.1,5,10.2s0.4,0.2,0.6,0.3l0.2,0.7c0,0.2,0.2,0.4,0.3,0.6C6.2,11.9,6.4,12,6.7,12H7
		c0.2,0,0.4-0.1,0.6-0.2C7.8,11.6,8,11.5,8,11.2l0.2-0.7c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.2,0.5-0.3l0.7,0.2
		C10.2,10.2,10.4,10.2,10.6,10.1z M8.3,8.4C7.9,8.8,7.4,9,6.8,9c-0.5,0-1-0.2-1.4-0.6S4.8,7.5,4.8,7S5,6,5.4,5.6S6.3,5,6.8,5
		c0.6,0,1,0.2,1.4,0.6S8.8,6.5,8.8,7S8.7,8,8.3,8.4z"/>
            <path d="M21.3,16.9c0-0.3,0.1-0.6,0.1-0.9s0-0.6-0.1-0.9l0.8-0.6c0.2-0.2,0.4-0.5,0.5-0.8c0-0.3,0-0.6-0.2-0.9
		l-0.3-0.5c-0.2-0.3-0.4-0.5-0.7-0.6c-0.3-0.1-0.6-0.1-0.9,0l-1,0.3c-0.2-0.2-0.5-0.3-0.7-0.5c-0.2-0.1-0.5-0.3-0.8-0.4l-0.2-1
		c-0.1-0.3-0.2-0.6-0.5-0.8C17.1,9.1,16.8,9,16.5,9h-0.6c-0.3,0-0.6,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.5,0.8l-0.2,1
		c-0.3,0.1-0.5,0.2-0.8,0.4s-0.5,0.3-0.7,0.5l-0.9-0.3c-0.3-0.1-0.6-0.1-0.9,0s-0.5,0.3-0.7,0.6L10,12.7c-0.2,0.3-0.2,0.6-0.2,0.9
		c0.1,0.3,0.2,0.6,0.5,0.8l0.8,0.7c0,0.3,0,0.6,0,0.9s0,0.6,0,0.9l-0.8,0.7C10,17.8,9.9,18,9.8,18.4c0,0.3,0,0.6,0.2,0.9l0.3,0.5
		c0.2,0.3,0.4,0.5,0.7,0.6s0.6,0.1,0.9,0l0.9-0.3c0.2,0.2,0.5,0.3,0.7,0.5s0.5,0.3,0.8,0.4l0.2,1c0.1,0.3,0.2,0.6,0.5,0.8
		c0.2,0.2,0.5,0.3,0.9,0.3h0.6c0.3,0,0.6-0.1,0.9-0.3c0.2-0.2,0.4-0.5,0.5-0.8l0.2-1c0.3-0.1,0.5-0.2,0.8-0.4
		c0.2-0.1,0.5-0.3,0.7-0.5l1,0.3c0.3,0.1,0.6,0.1,0.9,0c0.3-0.1,0.5-0.3,0.7-0.6l0.3-0.5c0.2-0.3,0.2-0.6,0.2-0.9
		c0-0.3-0.2-0.6-0.5-0.8L21.3,16.9z M18.3,18.1C17.7,18.7,17,19,16.2,19c-0.8,0-1.5-0.3-2.1-0.9c-0.6-0.6-0.9-1.3-0.9-2.1
		c0-0.8,0.3-1.5,0.9-2.1c0.6-0.6,1.3-0.9,2.1-0.9c0.8,0,1.5,0.3,2.1,0.9c0.6,0.6,0.9,1.3,0.9,2.1C19.2,16.8,18.9,17.5,18.3,18.1z"/>
    </svg>
);

export default MachineIcon;