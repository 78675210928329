import React from 'react';

const ChamberIcon = ({ color, size }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <g>
            <path d="M21.4,4.6C21,4.2,20.5,4,20,4H4C3.5,4,3,4.2,2.6,4.6S2,5.5,2,6v12c0,0.5,0.2,1,0.6,1.4S3.5,20,4,20h16
		c0.5,0,1-0.2,1.4-0.6S22,18.5,22,18V6C22,5.5,21.8,5,21.4,4.6z M20,18H4V6h16V18z"/>
            <path d="M7.8,14.8C9,15.6,10.4,16,12,16s3-0.4,4.2-1.2C17.4,14,18,13.1,18,12c0-1.1-0.6-2-1.8-2.8C15,8.4,13.6,8,12,8
		S9,8.4,7.8,9.2C6.6,10,6,10.9,6,12C6,13.1,6.6,14,7.8,14.8z M9.2,10.6C10,10.2,10.9,10,12,10s2,0.2,2.8,0.6C15.6,11,16,11.5,16,12
		s-0.4,1-1.2,1.4C14,13.8,13.1,14,12,14s-2-0.2-2.8-0.6C8.4,13,8,12.5,8,12S8.4,11,9.2,10.6z"/>
        </g>
    </svg>
);

export default ChamberIcon;