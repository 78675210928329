import React, {useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {Redirect, useLocation} from "react-router-dom";
import _ from "lodash";
import VSMLoadingAnimation from "../VSMLoadingAnimation";
import parseSearchQueryParams from "../../utils/searchParams";
import {useQuery} from "@apollo/client";
import ProfileQuery from "../../graphql/queries/profile.graphql";
import useProfile from "../../hooks/useProfile";
import {useDispatch} from "react-redux";
import {setUser} from "../../actions/auth";

interface Props {

}

const KeycloakLoginHandler = ({}: Props) => {
    const { keycloak, initialized } = useKeycloak()
    const location = useLocation();
    const searchParams = parseSearchQueryParams(location.search);
    const dispatch = useDispatch();
    const profile = useProfile()

    useEffect(() => {
        if (profile) {
            dispatch(setUser(profile))
        }
    }, []);

    if (initialized && keycloak.authenticated) {
        return <Redirect to={_.get(searchParams, 'redirect', '/')}/>
    } else {
        return <div>Initializing Keycloak...</div>
    }
}

export default KeycloakLoginHandler;
