import React from 'react';

const DepartmentIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path d="M5.4,9.6C5,9.2,4.6,9,4,9C3.5,9,3,9.2,2.6,9.6C2.2,10,2,10.4,2,11c0,0.5,0.2,1,0.6,1.4S3.5,13,4,13
		c0.6,0,1-0.2,1.4-0.6C5.8,12,6,11.5,6,11C6,10.4,5.8,10,5.4,9.6z"/>
        <path d="M4,14c-1.2,0-2.2,0.2-2.9,0.7S0,15.7,0,16.4V17c0,0.3,0.1,0.5,0.3,0.7C0.5,17.9,0.7,18,1,18h3.5v-1.6
		c0-0.4,0.1-0.8,0.2-1.2c0.1-0.4,0.3-0.8,0.5-1.1C5,14,4.8,14,4.6,14C4.4,14,4.2,14,4,14z"/>
        <path d="M12,12c0.8,0,1.6-0.3,2.1-0.9C14.7,10.5,15,9.8,15,9c0-0.9-0.3-1.6-0.9-2.1S12.8,6,12,6
		c-0.8,0-1.5,0.3-2.1,0.9S9,8.1,9,9c0,0.8,0.3,1.5,0.9,2.1C10.5,11.7,11.2,12,12,12z M11.3,8.3C11.5,8.1,11.7,8,12,8
		s0.5,0.1,0.7,0.3C12.9,8.5,13,8.7,13,9s-0.1,0.5-0.3,0.7C12.5,9.9,12.3,10,12,10s-0.5-0.1-0.7-0.3C11.1,9.5,11,9.3,11,9
		S11.1,8.5,11.3,8.3z"/>
        <path d="M22.9,14.7C22.2,14.2,21.2,14,20,14c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0.1c0.2,0.3,0.4,0.7,0.5,1.1
		c0.1,0.4,0.2,0.8,0.2,1.2V18H23c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-0.6C24,15.7,23.6,15.1,22.9,14.7z"/>
        <path d="M16.3,13.8c-0.5-0.3-1.2-0.6-1.9-0.8c-0.7-0.2-1.6-0.2-2.4-0.2c-0.9,0-1.7,0.1-2.4,0.2
		c-0.7,0.2-1.4,0.4-1.9,0.8c-0.5,0.3-0.9,0.7-1.2,1.2S6,15.8,6,16.4V17c0,0.3,0.1,0.5,0.3,0.7C6.5,17.9,6.7,18,7,18h10
		c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7v-0.6c0-0.5-0.1-1-0.4-1.5S16.9,14.1,16.3,13.8z M8.1,16c0.2-0.3,0.6-0.6,1.4-0.9
		c0.8-0.2,1.6-0.4,2.5-0.4s1.8,0.1,2.5,0.4c0.8,0.2,1.2,0.5,1.4,0.9H8.1z"/>
        <path d="M21.4,12.4C21.8,12,22,11.5,22,11c0-0.6-0.2-1-0.6-1.4C21,9.2,20.6,9,20,9c-0.5,0-1,0.2-1.4,0.6
		C18.2,10,18,10.4,18,11c0,0.5,0.2,1,0.6,1.4S19.5,13,20,13C20.6,13,21,12.8,21.4,12.4z"/>
    </svg>
);

export default DepartmentIcon;