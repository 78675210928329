import React from 'react';
import {useTranslation} from "react-i18next";
import {Bar, BarChart, ResponsiveContainer, XAxis, YAxis} from "recharts"
import moment from "moment";
import _ from 'lodash';

interface Props {
    data?: any[]
}

const StackedBarChart = ({data}: Props) => {
    const {t} = useTranslation();

    const healthLevelColors = {
        critical: "#DD1541",
        unsatisfactory: "#F8AC30",
        good: "#599460",
        default: "#4D7CAB",
    };


    const dummy = [
        {
            timestamp: Date.now() - 302800000,
            value: {
                healthIndex: 1.23,
                healthLevel: "default"
            }
        }, {
            timestamp: Date.now() - 92800000,
            value: {
                healthIndex: 0.23,
                healthLevel: "good"
            }
        }, {
            timestamp: Date.now() - 45800000,
            value: {
                healthIndex: 0.53,
                healthLevel: "default"
            }
        }, {
            timestamp: Date.now() - 22800000,
            value: {
                healthIndex: 0.64,
                healthLevel: "unsatisfactory"
            }
        }, {
            timestamp: Date.now() - 11800000,
            value: {
                healthIndex: 0.18,
                healthLevel: "good"
            }
        }, {
            timestamp: Date.now() - 7800000,
            value: {
                healthIndex: 1.02,
                healthLevel: "unsatisfactory"
            }
        }, {
            timestamp: Date.now() - 6000000,
            value: {
                healthIndex: 0.98,
                healthLevel: "critical"
            }
        },{
            timestamp: Date.now() - 4500000,
            value: {
                healthIndex: 0.05,
                healthLevel: "good"
            }
        },
    ]

    const chronologicalData = data ? [...data].reverse() : [];

    const transformedData: [{}] = chronologicalData.length !== 0 ? [
        chronologicalData.reduce((acc, item, index) => {

            let interval;
            if (index === chronologicalData.length - 1) {
                const diff = moment(Date.now()).valueOf() - moment(item.timestamp).valueOf();
                interval = diff < 0 ? null : diff;
            } else {
                interval = moment(chronologicalData[index + 1].timestamp).valueOf() - moment(item.timestamp).valueOf();
            }

            const timestamp = moment(item.timestamp).valueOf();
            const value = _.get(item, 'value.healthIndex');
            const healthLevel = _.get(item, 'value.healthLevel');

            if (interval !== null && value !== null && healthLevel !== null) {
                acc[timestamp] = {
                    timestamp,
                    value,
                    healthLevel,
                    interval
                };
            }

            return acc;

        }, {})
    ]: [];

//         : [dummy.reduce((acc, item, index) => {
//
//         let interval;
//         if (dummy && index === dummy.length - 1) {
//             interval = moment(Date.now()).valueOf() - moment(item.timestamp).valueOf();
//         } else {
//             interval = moment(dummy[index + 1].timestamp).valueOf() - moment(item.timestamp).valueOf();
//         }
//
//         acc[moment(item.timestamp).valueOf()] = {
//             timestamp: moment(item.timestamp).valueOf(),
//             value: item.value.healthIndex,
//             healthLevel: item.value.healthLevel,
//             interval: interval
//         };
//         return acc;
//     }, {})
// ];

    const bars = () => {
        if (transformedData.length === 0) {
            return [];
        }

        return transformedData.map((item) => {
            let i = 0;
            let entries = Object.entries(item);

            return entries.map(([key, value]) => {
                    const bar = <Bar
                        radius={(i === 0 && [4, 0, 0, 4]) || (i === (entries.length - 1) && [0,4,4,0])}
                        key={key}
                        dataKey={`${key}.interval`}
                        fill={healthLevelColors[value.healthLevel]}
                        stackId="a"
                    />;
                    i++;

                    return bar;
                }
            )}
        )
    }

    const legendTicks: [] = () => {
        if (transformedData.length === 0) {
            return [];
        }

        const minTimestamp = _.minBy(Object.values(transformedData[0]), 'timestamp').timestamp;
        const maxTimestamp = _.maxBy(Object.values(transformedData[0]), 'timestamp').timestamp;

        const totalRange = maxTimestamp - minTimestamp;
        const quarterInterval = totalRange / 4;

        let timestamp = minTimestamp;
        const ticks = [];
        for (let i = 0; i <= 4; i++) {
            ticks.push(timestamp);
            timestamp += quarterInterval;
        }

        return ticks
    }


    const LabelRow = (ticks: []) => {
        if (ticks.length === 0) {
            return null;
        }

        return (
            <div style={{display: "flex", alignItems: "center"}}>
                {ticks.map((tick, index) => (
                    <React.Fragment key={index}>
                        <div>{moment(tick).format('DD.MM')}</div>
                        {index < ticks.length - 1 && (
                            <hr style={{
                                flexGrow: 1,
                                border: "none",
                                borderTop: "1px solid currentColor",
                                margin: "0 8px"
                            }}/>
                        )}
                    </React.Fragment>
                ))}
            </div>
        );
    };

    return (
        <div className={'flex-grow-1'}>
            <ResponsiveContainer width="99%" height={20} style={{borderRadius: "4px"}}>
                <BarChart data={transformedData} layout="vertical" margin={{left: 16, right: 16}} stackOffset="expand">
                    <XAxis hide type="number"/>
                    <YAxis type="category" hide/>
                    {bars()}
                </BarChart>
            </ResponsiveContainer>
            {LabelRow(legendTicks())}
        </div>
    );
}

export default StackedBarChart;