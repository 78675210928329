// @flow
import React, {Component} from "react"
import {graphql} from '@apollo/client/react/hoc';
import ComponentQuery from "./../../graphql/queries/ComponentInfoBox.graphql";
import _ from "lodash";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import ComponentTypeIcon from "../ComponentTypeIcon";
import FontAwesomeIcon from '../Icons/FontAwesomeIcon';
import {withTranslation} from "react-i18next";
import OneLineLabel from "./../OneLineLabel";
import {compose, withProps} from "recompose";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import ComponentImage from "../ComponentImage";
import {Link, withRouter} from "react-router-dom";
import CULabel from "../CULabel";

type Props = {
    componentId: string,
    wrapperStyle: any,
    disableBreadcrumb?: boolean,
    componentData?: any,
    component: any
}




class ComponentInfoBox extends Component<Props> {

    static defaultProps = {
        wrapperStyle: {padding: '8px 16px'}
    };

    render() {

        const {component, t, wrapperStyle, disableBreadcrumb} = this.props;

        if (!component){
            return null;
        }

        return <div style={wrapperStyle}>
            <div className="flex-row">
                <div style={{background: 'white'}}>
                    <ComponentImage link={_.get(component,'image.link')} size={100} componentId={component.id} />
                </div>
                <div style={{overflow: 'hidden'}} className="flex-grow-1 ph-10 ml-5">
                    <div style={{fontSize: 18}}>
                          <span style={{minWidth: "1.5em", display: 'inline-block'}}>
                            <ComponentTypeIcon size={18} color={'black'} componentTypeIndex={_.get(component,'componentType.index')}/>
                          </span>
                        {
                            _.get(component, 'access.read', true)
                                ? <Link to={`/infrastructure/${component.id}`}>
                                    <span className={"link-dark"}>
                                          {_.get(component,'name',t('Unknown name'))}
                                      </span>
                                </Link>
                                : <span>
                                      {_.get(component,'name',t('Unknown name'))}
                                  </span>
                        }
                        {
                            _.get(component,'read') ? <span style={{float: 'right'}}><CULabel componentId={component.id}/></span> : null
                        }
                    </div>
                    {
                        component.product ? <div>
                            <OneLineLabel><span>{t('Serial')}: </span>
                                <span>{_.get(component, 'serialNo')}</span></OneLineLabel>
                            <OneLineLabel><span>{t('Catalog No')}: </span>
                                <span>{_.get(component, 'product.catalogNo')}</span></OneLineLabel>
                            <OneLineLabel><span>{t('Manufacturer')}: </span>
                                <span>{_.get(component, 'product.manufacturer.name')}</span></OneLineLabel>
                        </div> : <div>
                <span style={{minWidth: "1.5em", display: 'inline-block'}}>
                    <FontAwesomeIcon name={'sitemap'}/>
                </span> {component.childrenCount} {t('Sub Components')}
                        </div>
                    }
                </div>
            </div>
            {disableBreadcrumb || _.get(component, 'path', []).length <= 1 ? null : <div className="mt-15 pv-5 ph-10 border-radius-5 bg-light-grey">
                <ComponentPathBreadcrumb hideLastItem path={_.get(component, 'path', [])}/>
            </div>}
        </div>;
    }

}


export default compose(
  withRouter,
    // connect(mapStateToProps),
    graphql(ComponentQuery, {
        name: 'getComponent',
        skip: props => !props.componentId || props.componentData || _.get(props.componentData, 'access.read') === false,
        options: props => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.componentId
            }
        }),
    }),
    waitWhileLoading('getComponent',null, {
        optional: true
    }),
    withProps((props)=>({
        component: _.get(props, 'getComponent.component',props.componentData)
    })),
    withTranslation()
)(ComponentInfoBox);
