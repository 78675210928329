import React from 'react';

const StockIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path className="st0" d="M16.7,16.1l-4.2-9.9c-0.2-0.4-0.4-0.7-0.8-0.9C11.4,5.1,11.1,5,10.7,5H5C4.7,5,4.5,5.1,4.3,5.3
		C4.1,5.5,4,5.7,4,6v5H3c-0.3,0-0.5,0.1-0.7,0.3C2.1,11.5,2,11.7,2,12v3.8c-0.3,0.3-0.6,0.6-0.8,1C1.1,17.2,1,17.6,1,18
		c0,0.8,0.3,1.5,0.9,2.1C2.5,20.7,3.2,21,4,21c0.6,0,1.2-0.2,1.7-0.5c0.5-0.4,0.9-0.9,1.1-1.5h3.5c0.3,0.6,0.7,1.1,1.3,1.5
		s1.2,0.5,1.9,0.5c1,0,1.8-0.3,2.5-1c0.7-0.7,1-1.5,1-2.5c0-0.2,0-0.5-0.1-0.7S16.8,16.3,16.7,16.1z M4.7,18.7C4.5,18.9,4.3,19,4,19
		s-0.5-0.1-0.7-0.3C3.1,18.5,3,18.3,3,18s0.1-0.5,0.3-0.7C3.5,17.1,3.7,17,4,17s0.5,0.1,0.7,0.3C4.9,17.5,5,17.7,5,18
		S4.9,18.5,4.7,18.7z M10.1,16.5c0,0.2-0.1,0.3-0.1,0.5H6.8c-0.2-0.6-0.6-1.1-1.1-1.5C5.2,15.2,4.6,15,4,15v-2h1.2l0.5,0.5
		c0.3,0.3,0.6,0.7,1,1s0.7,0.7,1,1L8.1,16h2.2C10.2,16.2,10.2,16.3,10.1,16.5z M8.9,14L6,11V7h4.7l3,7H8.9z M14.6,18.6
		c-0.3,0.3-0.6,0.4-1.1,0.4s-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1.1c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1.1-0.4
		s0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1C15,17.9,14.9,18.3,14.6,18.6z"/>
        <path className="st0" d="M22.7,18.3C22.5,18.1,22.3,18,22,18h-2V5c0-0.3-0.1-0.5-0.3-0.7C19.5,4.1,19.3,4,19,4s-0.5,0.1-0.7,0.3
		C18.1,4.5,18,4.7,18,5v14c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h3c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
		S22.9,18.5,22.7,18.3z"/>
    </svg>
);

export default StockIcon;