// @flow
import React, {Component} from "react"
import _ from 'lodash'
import {graphql, withApollo} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import componentListQuery from './../../graphql/queries/componentList-Search.graphql'
import type {ComponentListFilter, ComponentListSearch} from "../../graphql/operation-result-types.flow";
import ComponentPathBreadcrumb from "../ComponentPathBreadcrumb";
import {Modal, Spin} from "antd";
import {Button} from "@dreebit/react-components";
import OneLineLabel from "../OneLineLabel";
import FillQueryTable from "../QueryTable/fill";
import ComponentImage from "../ComponentImage";
import customAttributesSearchable from "../../graphql/queries/customAttributesList-searchable.graphql";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import withProfile from "../../hoc/withProfile";
import isServiceProvider from "../../utils/isServiceProvider";
import ComponentListAllQuery from "../../graphql/queries/componentList-SearchAll.graphql";
import Toolbar from "../Toolbar";
import NavigationContainer from "../NavigationContainer";
import ComponentsMultiAction from "../ComponentsMultiAction";
import ComponentsCreateSelectionBookmark from "../ComponentsCreateSelectionBookmark";
import BookmarkQuery from "../../graphql/queries/bookmarkData.graphql";
import {DragSource} from "react-dnd";
import BodyRow from "./BodyRow";
import ComponentAlertBadges from "../ComponentAlertBadges";

type Props = {
    history: {
        push: Function,
    },
    params: {
        parentId?: number,
    },
    getComponentList: {
        loading: boolean,
        ...componentListSearchQuery
    },
    bookmarkCollapsed?: boolean,
    selectedItems: any[],
    onChangeSelectedItems?: (items) => void
}


type State = {
    showMultiAction: boolean,
}

class InfrastructureSearchResults extends Component<Props, State> {

    state = {
        showMultiAction: false,
        showSelectionForm: false,
        loadingSelection: false,
    };

    componentDidMount(){
        this.props.onRef(this)
    };
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    _handleSelectionAction = () => {

        this.setState({
            showMultiAction: true
        });

    };

    _hideMultiActionModal = () => {
        this.setState({
            showMultiAction: false,
            enableModalFooter: false,
        });
    };



    selectAll = () => {

        const {client, getComponentList, onChangeSelectedItems} = this.props;

        const variables = {...getComponentList.variables};
        variables.params.start = 0;
        variables.params.limit = undefined;

        this.setState({
            loadingSelection: true
        }, () => {
            client.query({
                query: ComponentListAllQuery,
                variables
            }).then((res) => {
                const allItems = _.get(res, 'data.componentList.components', []).map(item => item.id)
                onChangeSelectedItems(allItems)

                this.setState({
                    loadingSelection: false,
                });

            })
        })


    };

    _handleSaveSelection = () => {
        this.setState({
            showSelectionForm: true,
        });
    };

    _hideSelectionModal = () => {
        this.setState({
            showSelectionForm: false
        })
    };

    render() {

        const {getComponentList, t, params, customAttributesSearchableQuery, selectedItems, onChangeSelectedItems} = this.props;

        const {enableModalFooter} = this.state;

        const rowSelection = {
            selectedRowKeys: selectedItems,
            hideDefaultSelections: true,
            onSelectAll: (selected, selectedRows, changeRows) => {
                if (selected){
                    this.selectAll();
                }else {
                    // this.setState({
                    //     selectedItems: []
                    // });
                    onChangeSelectedItems([])
                }
            },
            onSelect: (record, selected, selectedRows) => {
                onChangeSelectedItems(selectedRows.map(item => item.id || item))
            },
        };

        const total = getComponentList['componentList'] ? getComponentList['componentList']['total'] : null;

        const customAttributes = _.get(customAttributesSearchableQuery, 'customAttributeList.customAttributes', []);
        let fields = _.get(params, 'fields', []);
        if (typeof fields === 'string') {
            fields = [fields]
        }

        let fieldColumns = [];
        if (fields.length && fields.map) {
            fields.forEach((field) => {
                if (field === 'name') return null;

                if (field === 'serialNo') {
                    return fieldColumns.push({
                        key: 'serialNo',
                        width: 150,
                        dataIndex: 'serialNo',
                        title: t('Serial No'),
                    })
                }

                if(field === 'catalogNo'){
                    return fieldColumns.push({
                        key: 'product.catalogNo',
                        dataIndex: ['product', 'catalogNo'],
                        width: 150,
                        title: t('Catalog No'),
                        render: (text, item) => {
                            if (!text && item.catalogNo) {
                                return item.catalogNo;
                            }
                            return text
                        }
                    })
                }

                const customAttribute = _.find(customAttributes,{
                    index: field
                });

                return fieldColumns.push({
                    key: field,
                    dataIndex: field,
                    width: 150,
                    title: _.get(customAttribute,'name',t(field)),
                })
            })
        }



        let fistCols = [
            {
                key: 'image.thumbnailLink',
                dataIndex: ['image', 'thumbnailLink'],
                width: 50,
                render: (text, record) => {
                    return <Link to={`/infrastructure/${record.id}`}><ComponentImage size={30} link={text}/></Link>
                }
            }, {
                key: 'name',
                dataIndex: 'name',
                title: t('Name'),
                sorter: true,
                width: 500,
                render: (text, record) => {
                    const activeAlerts = _.get(record, 'activeAlerts');

                    return <div style={{wordWrap: 'break-word', wordBreak: 'break-word', maxWidth: 500}}>
                        <div className={'flex-row space-between'}>
                            <Link to={`/infrastructure/${record.id}`}>
                                {text}
                            </Link>

                            <ComponentAlertBadges activeAlerts={activeAlerts}/>
                        </div>

                        <div style={{maxWidth: 500}}>
                            <OneLineLabel>
                                <ComponentPathBreadcrumb hideLastItem path={record.path}/>
                            </OneLineLabel>
                        </div>
                    </div>
                }
            }, {
                key: 'product.manufacturer.name',
                dataIndex: ['product', 'manufacturer', 'name'],
                width: 150,
                title: t('Manufacturer'),
                renderItem: (text, record) => {
                    let link = <Link to={`/infrastructure/${record.id}`}>
                        <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>{text}</div>
                    </Link>;
                    if (!text && record.manufacturerName) {
                        link = <Link to={`/infrastructure/${record.id}`}>
                            <div style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-word'
                            }}>{record.manufacturerName}</div>
                        </Link>;
                    }
                    return <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>
                        {link}
                    </div>
                }
            }
        ];

        if (isServiceProvider(this.props.resources)){
            fistCols.push({
                key: 'client.id',
                dataIndex: ['client', 'name'],
                width: 150,
                title: t('Client')
            })
        }

        const lastCols = [
            {
                key: 'runtime',
                width: 100,
                dataIndex: 'runtime',
                title: t('Run Time'),
                render: (text, item) => {
                    return text;
                }
            }

        ];

        const columns = [
            ...fistCols,
            ...fieldColumns,
            ...lastCols
        ];

        const rowSource = {
            beginDrag(props) {
                return {
                    id: _.get(props, 'data-row-key', null),
                };
            },
        };

        const DragableBodyRow = DragSource('bookmark', rowSource, connect => ({
            connectDragSource: connect.dragSource(),
        }))(BodyRow);

        const components = !this.props.bookmarkCollapsed
            ? {
                body: {
                    row: DragableBodyRow,
                }
            }
            : {};

        return <div className={"full-size"}>

            <NavigationContainer navigationBar={<div>
                {this.props.selectedItems?.length ?
                    <Toolbar
                        style={{height: 41}}
                        renderLeft={() => <div>
                            <Button
                                icon={'snippets'}
                                size={'small'}
                                onClick={this._handleSelectionAction}>{t('Action with total components', {total: this.props.selectedItems?.length})}</Button>
                            <Button
                                className={"ml-10"}
                                icon={'save'}
                                size={'small'}
                                onClick={this._handleSaveSelection}>{t('Save total component selection', {total: this.props.selectedItems?.length})}</Button>
                        </div>}
                        borderBottom={true}/>
                    : null}
                {this.state.loadingSelection ?
                    <div style={{height: 41, paddingTop: 8, paddingLeft: 16}}>
                        <Spin size={"small"}/>
                    </div>
                    : null}
            </div>}>
                <Modal
                    destroyOnClose
                    visible={this.state.showMultiAction}
                    title={t('Multi Action')}
                    footer={enableModalFooter ? [
                        <Button key={'close'} type={'primary'} onClick={this._hideMultiActionModal}>{t('Close')}</Button>
                    ] : null}
                    onCancel={this._hideMultiActionModal}
                    onOk={this._hideMultiActionModal}
                >
                    <ComponentsMultiAction
                        componentIds={this.props.selectedItems}
                        onDone={() => {
                            this.props.getComponentList.refetch();
                            this._hideMultiActionModal();
                        }}
                    />
                </Modal>
                <Modal
                    destroyOnClose
                    visible={this.state.showSelectionForm}
                    title={t('Save selection')}
                    footer={null}
                    onCancel={this._hideSelectionModal}
                    onOk={this._hideSelectionModal}
                >
                    <ComponentsCreateSelectionBookmark
                        onCreated={() => {
                            this._hideSelectionModal();
                            onChangeSelectedItems([])
                        }}
                        componentIds={this.props.selectedItems} />

                </Modal>
                <FillQueryTable
                    rowSelection={rowSelection}
                    components={components}
                    size={'middle'}
                    loading={getComponentList.loading}
                    bordered={false}
                    rowKey={"id"}
                    columns={columns}
                    itemsKeyPath={'componentList.components'}
                    totalKeyPath={'componentList.total'}
                    limitKeyPath={'params.limit'}
                    startKeyPath={'params.start'}
                    query={getComponentList}
                />
            </NavigationContainer>



        </div>



    }

}


/**
 *
 *
 * @param props
 * @returns {ComponentListFilter}
 *

 */
export const filterFromProps = (props: Props): ComponentListFilter => {
    const blacklist = ['query', 'fields','selectionBookmark'];
    const keys = ['parentId', 'manufacturerId', 'componentTypeIndex', 'clientId', 'serviceProviderId', 'productId'];
    const params = _.get(props, 'params', {});
    const componentOptions = _.get(params, 'componentOptions');

    const result: ComponentListFilter = keys.reduce((acc, key) => {
        const val = _.get(props, 'params.' + key, null);

        if (val) {
            if (key === 'parentId') {
                acc[key] = {
                    operator: 'e',
                    value: val,
                    direct: componentOptions && _.isArray(componentOptions) ? componentOptions.indexOf('subComponentsOnly') !== -1 : componentOptions ? componentOptions.includes('subComponentsOnly') : null
                }
            } else {
                acc[key] = {
                    operator: 'in',
                    value: val
                }
            }
        }
        return acc;
    }, {});

    if (componentOptions && componentOptions.indexOf('stockSearch') !== -1) {
        result.parentComponentTypeIndex = {
            operator: 'in',
            value: ['stock']
        }
    }

    if (!result.componentTypeIndex && (!componentOptions || componentOptions.indexOf('findAllComponentTypes') === -1)){
        result.product = {
            value: true
        }
    }

    const data = _.get(props,'bookmarkQuery.bookmark.data');
    if (data) {
        const bookmarkData = JSON.parse(data);
        const componentIds = _.get(bookmarkData, 'componentIds', []);
        if (componentIds.length) {
            result["id"] = {
                value: componentIds
            }
        }
    }

    Object.keys(params).forEach((key: string) => {
        if (!result[key]) {
            _.set(result, key, {
                value: params[key]
            })
        }
    });

    blacklist.forEach((key: string) => {
        if (result[key]) delete result[key];
    })

    return result;
};


export const searchParamsFromProps = (props: Props): ComponentListSearch => {
    const {query, fields} = _.get(props, 'params', {});
    if (!query || !query.length) return undefined;

    const result = {
        query: null,
        fields: ["name", "serialNo", "catalogNo"]
    };
    if (query) {
        result.query = query
    }
    if (fields) {
        result.fields = fields
    }

    return result;
};


export default compose(
    withTranslation(),
    withRouter,
    graphql(BookmarkQuery,{
        name: 'bookmarkQuery',
        skip: (props) => !_.get(props,'params.selectionBookmark'),
        options: (props) => {
            return {
                variables: {
                    bookmarkId: _.get(props,'params.selectionBookmark')
                }
            }
        }
    }),
    waitWhileLoading('bookmarkQuery',null, {
        optional: true,
        allowErrors: true,
        allowToasts: true
    }),
    graphql(customAttributesSearchable, {
        name: 'customAttributesSearchableQuery',
        options: () => ({
            variables: {}
        }),
    }),
    waitWhileLoading('customAttributesSearchableQuery', null, {
        loaderSize: 'small',
    }),
    graphql(componentListQuery, {
        name: 'getComponentList',
        options: props => {
            return {
                variables: {
                    params: {
                        filter: filterFromProps(props),
                        search: searchParamsFromProps(props),
                        start: 0,
                        limit: 30
                    }
                }
            }
        },
    }),
    withProfile(),
    withApollo,
)(InfrastructureSearchResults);
