// @flow
import React, {Component} from "react"
import {Breadcrumb} from "antd";
import {Icon} from "@dreebit/react-components";
import _ from 'lodash'
import {Link} from "react-router-dom";
import {compose, withProps} from "recompose";
import {graphql} from '@apollo/client/react/hoc';
import ComponentPathQuery from "./../../graphql/queries/ComponentPath.graphql";
import Loader from "../Loader";

type Props = {
    showSubComponents?: boolean,
    hideLastItem?: boolean,
    path: {
        id: string | number,
        name: string
    }[],
    componentId: any,
}

export const getPathText = (path) => {
    let pathTemp = path;

    //remove URL params if present
    if (path.includes("&")) {
        pathTemp = path.split('&')[0]
    }
    if (pathTemp.includes("?")) {
        pathTemp = path.split('?')[0]
    }

    return pathTemp.reduce((acc, cur) => acc === '' ? cur.name : `${acc} > ${cur.name}`, '');
};

class ComponentPathBreadcrumb extends Component<Props> {


    _renderItem = (item) => {

        if (!item || !item.id) {
            return null;
        }

        const sharedIcon = item.shared ? <Icon style={{verticalAlign: "middle"}} type="share-alt"/> : null;

        if (!_.get(item, 'access.read') || _.get(item, 'access.read') === false) {
            return <Breadcrumb.Item key={item.id}><span>{sharedIcon} {item.name}</span></Breadcrumb.Item>
        }

        if (this.props.onClick) {
            return <Breadcrumb.Item key={item.id}>
                <span onClick={() => this.props.onClick(item)}>{sharedIcon} {item.name}</span>
            </Breadcrumb.Item>
        }

        return <Breadcrumb.Item key={item.id}>
            <Link to={`/infrastructure/${item.id}`}>{sharedIcon} {item.name}</Link>
        </Breadcrumb.Item>;
    };

    render() {

        const {hideLastItem, loading, path} = this.props;

        if (loading) {
            return <Loader size={'small'}/>
        }

        return <Breadcrumb className={"display-inline"}>
            {path.filter((item, index) => {
                if (hideLastItem) {
                    return this.props.path.length - 1 !== index;
                }
                return true;
            }).map(this._renderItem)}
        </Breadcrumb>;
    }
}

export default compose(
    graphql(ComponentPathQuery, {
        name: 'componentPathQuery',
        skip: (props: Props) => _.size(props.path) || !props.componentId,
        options: (props: Props) => ({
            fetchPolicy: 'cache-first',
            variables: {
                id: props.componentId,
            },
            skip: props.path
        })
    }),
    withProps((props) => ({
        loading: _.get(props, 'componentPathQuery.loading', false),
        path: props.path && props.path.length ? props.path : _.get(props, 'componentPathQuery.componentInfo.path', [])
    }))
)(ComponentPathBreadcrumb);
