import React from 'react';
import SplitPanel from "../SplitPanel";
import {useTranslation} from "react-i18next";
import StackedBarChart from "../StackedBarChart";
import {Col, Grow, Row} from "../Flex";
import FontAwesomeIcon from "../Icons/FontAwesomeIcon";

interface Props {
    healthIndex: number,
    healthLevel: string,
    healthData: []
}

const ComponentHealthIndexSplitPanel = ({healthIndex, healthLevel, healthData}: Props) => {
    const {t} = useTranslation();

    const healthIndexDisplay = <div className={"flex flex-col flex-align-items-center"}>
        <div style={{fontSize: 32, fontWeight: "bold", lineHeight: 1.2}}>
            {Math.round(healthIndex * 100) / 100}
        </div>
    </div>

    const getHealthStatus = (healthLevel) => {
        if (healthLevel === "critical") {
            return "error-bg"
        } else if (healthLevel === "unsatisfactory") {
            return "warning-bg"
        } else {
            return "success-bg"
        }
    }

    return (
        <Row className={'split-panel mb-15'}>
            <Col
                className={'left-content-wrapper flex-align-items-center flex-center ' + getHealthStatus(healthLevel)}
                style={{getHealthStatus}}
            >
                {healthIndexDisplay}
                <Row className={'description'}>{healthLevel}</Row>
            </Col>
            <Grow className={'right-content-wrapper flex flex-align-items-space-between flex-col'}>
                    <Row className={'flex-align-items-end'}>
                    <span className={'title'}>
                        {t("Health Indication")}
                    </span>
                    </Row>
                    <StackedBarChart data={healthData}/>
            </Grow>
        </Row>
    )
}

export default ComponentHealthIndexSplitPanel;
