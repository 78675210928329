import React from 'react';

const ClientIcon = ({ color, size }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path class="st0" d="M21.4,7.6C21,7.2,20.5,7,20,7h-8V5c0-0.5-0.2-1-0.6-1.4S10.5,3,10,3H4C3.5,3,3,3.2,2.6,3.6S2,4.5,2,5v14
		c0,0.5,0.2,1,0.6,1.4S3.5,21,4,21h16c0.5,0,1-0.2,1.4-0.6S22,19.5,22,19V9C22,8.5,21.8,8,21.4,7.6z M6,19H4v-2h2V19z M6,15H4v-2h2
		V15z M6,11H4V9h2V11z M6,7H4V5h2V7z M10,19H8v-2h2V19z M10,15H8v-2h2V15z M10,11H8V9h2V11z M10,7H8V5h2V7z M20,19h-8v-2h2v-2h-2v-2
		h2v-2h-2V9h8V19z"/>
        <rect x="16" y="11" class="st0" width="2" height="2"/>
        <rect x="16" y="15" class="st0" width="2" height="2"/>
    </svg>
);

export default ClientIcon;