// @flow
import React, {Component} from 'react';
import ComponentProductInfoQuery from '../../graphql/queries/component-productInfo.graphql';
import _ from "lodash";
import {withTranslation} from "react-i18next";
import waitWhileLoading from "../../hoc/waitWhileLoading";
import {compose} from "redux";
import {graphql} from '@apollo/client/react/hoc';
import ComponentImage from "../ComponentImage";
import withResources from "../../hoc/withResources";
import {Icon} from "@dreebit/react-components";
import {Link} from "react-router-dom";

type Props = {
  componentId: string,
  componentProductInfoQuery: any,
  t: Function
};

type State = {
}

class ProductInfoWidget extends Component<Props, State> {

  componentDidMount() {}

  render() {
    const {componentId, componentProductInfoQuery, t} = this.props;
    const component = _.get(componentProductInfoQuery, 'component', {});
    const manufacturerLink = _.get(component, 'product.manufacturerLink');

    const imageAndInfo = <div className='flex-row'>
      <Link to={`/infrastructure/${componentId}/product`}>
        <div>
          <ComponentImage editable={false} link={_.get(component, 'product.image.thumbnailLink')}/>
        </div>
      </Link>
      <div className='flex-grow-1 flex-col ml-10'>
        <Link to={`/infrastructure/${componentId}/product`}>
          <span className='larger-text bold' style={{textDecoration: 'none', color: 'black'}}>
            {_.get(component, 'product.name')}
          </span>
        </Link>
        <span className='text-italic'>{_.get(component, 'product.componentType.name')}</span>
        <span>{t('Manufacturer')}: {_.get(component, 'product.manufacturer.name')}</span>
        <span>{t('Catalog No')}: {_.get(component, 'product.catalogNo')}</span>
        {manufacturerLink ? <div>
          <a target={"_BLANK"} title={_.get(component, 'product.name')}
             href={manufacturerLink}>{t("Find more information")} <Icon style={{verticalAlign: 'middle'}}
                                                                        type={'link'}/> </a>
        </div> : null}
      </div>
    </div>;

    return <div className='flex-row'>
      {imageAndInfo}
    </div>
        ;
  }
}

export default compose(
    graphql(ComponentProductInfoQuery, {
      name: 'componentProductInfoQuery',
    options: (props: Props) =>({
      variables: {
        componentId: props.componentId
      }
    }),
  }),
  waitWhileLoading('componentProductInfoQuery', 'component'),
  withTranslation(),
    withResources(),
)(ProductInfoWidget);
