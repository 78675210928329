import React from 'react';

const PoolIcon = ({color, size}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <rect x="9" y="19" className="st0" width="2" height="2"/>
        <rect x="13" y="19" className="st0" width="2" height="2"/>
        <path className="st0" d="M21.7,7.2c-0.2-0.3-0.5-0.6-0.9-0.7l-8-3.2c-0.2-0.1-0.5-0.2-0.8-0.2s-0.5,0.1-0.8,0.2l-8,3.2
		C2.9,6.6,2.6,6.9,2.3,7.2C2.1,7.6,2,7.9,2,8.3V19c0,0.5,0.2,1,0.6,1.4S3.5,21,4,21h4v-8h8v8h4c0.5,0,1-0.2,1.4-0.6S22,19.5,22,19
		V8.3C22,7.9,21.9,7.6,21.7,7.2z M20,19h-2v-6c0-0.5-0.2-1-0.6-1.4S16.5,11,16,11H8c-0.5,0-1,0.2-1.4,0.6S6,12.5,6,13v6H4V8.3l8-3.2
		l8,3.2V19z"/>
        <rect x="11" y="16" className="st0" width="2" height="2"/>
    </svg>
);

export default PoolIcon;