// @flow
import React from "react"
import {useTranslation} from "react-i18next";
import SplitPanelList from "../SplitPanelList";
import _ from "lodash";

type Props = {
    componentId: string,
    faults: any[]
}

const ComponentAlertsSplitPanel = ({componentId, faults}: Props) => {
    const {t} = useTranslation();

    const errorCount = faults.filter(alert => alert.level === "error").length;
    const warningCount = faults.filter(alert => alert.level === "warning").length;
    const infoCount = faults.filter(alert => alert.level === "info").length;
    const highestFaultLevel = errorCount ? "error" : warningCount ? "warning" : infoCount ? "info" : undefined;

    const totalHighestAlertCount = faults.filter(alert => alert.level === highestFaultLevel).length;
    const hasActiveAlerts = totalHighestAlertCount > 0;

    return <SplitPanelList
        items={[
            {
                link: `/infrastructure/${componentId}/alerts`,
                largeTitle: totalHighestAlertCount,
                title: hasActiveAlerts ? `${t(`active alert`, {count: totalHighestAlertCount})}` : t("No active alerts"),
                description: hasActiveAlerts ? t('Click here to show all active alerts of this component') : t("Click here to show all previous alerts of this component"),
                icon: highestFaultLevel === "info" ? "info" : "exclamation-triangle",
                leftClass: hasActiveAlerts ? `${highestFaultLevel}-bg` : undefined
            }
        ]}
    />
}

export default ComponentAlertsSplitPanel;
