import * as React from "react"
const AasSVG = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            enableBackground: "new 0 0 18 18",
        }}
        viewBox="0 0 18 18"
        {...props}
    >
        <path
            d="M.8 6.1c0-.5.2-1 .6-1.4l1.2-1.2c.4-.4.9-.6 1.4-.6h10c.5 0 1 .2 1.4.6l1.2 1.2c.4.4.6.9.6 1.4v7.8c0 .7-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2V9.7c0-.4-.1-.8-.4-1.2-.4-.4-.9-.7-1.4-.7H5c-.6 0-1.1.2-1.4.7-.3.4-.4.8-.4 1.2v4.2c0 .7-.5 1.2-1.2 1.2S.8 14.6.8 13.9V6.1z"
            style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#333",
            }}
        />
    </svg>
)
export default AasSVG
