// @flow
import React, {Component} from "react"
import {message, Upload} from 'antd';
import {Icon} from "@dreebit/react-components";
import _ from 'lodash';
import {connect} from "react-redux";
import {compose} from "recompose";
import * as axios from "axios";
import {withTranslation} from "react-i18next";
import base64ImageToBlob from "../../utils/base64File";
import ImageEditorModal from "../ImageEditorModal";
import keycloak from "../../keycloak";

const Dragger = Upload.Dragger;

type Size = 'small' | 'large';

type Props = {
    multiple: boolean,
    customContent?: any,
    accept: string,
    onChange: (fileList: any[])=> void,
    onUpload: Function,
    onUploadDone?: (uploadedFiles: any) => void,
    hideSuccessMessages: boolean,
    autoRemove: boolean,
    size: Size
}

class FileUploader extends Component<Props> {

    constructor(props) {
        super(props);

        this.uploadedFilesMap = {};

        this.state = {
            visible: false,
            loading: false,
            editImage: undefined,
            fileList: [],
        }

    }


    getHeader() {
        const token = keycloak.token
        return {
            authorization: token ? `Bearer ${token}` : null,
        }
    }

    uploadBase64File(data, oldFile) {
        const formData = new FormData();
        const blob = base64ImageToBlob(data);
        formData.append('file', blob, oldFile.name);

        return axios.post('/file', formData, {
            headers: this.getHeader()
        }).then(response => {
            const {size, type} = blob;
            return {
                response: response.data,
                blob: {
                    size,
                    type
                }
            }
        })
    }

    triggerChange(fileList) {
        if (this.props.onChange) {
            this.props.onChange(fileList);
        }
    }

    render() {

        const {multiple, accept, t, hideSuccessMessages, autoRemove, size, customContent} = this.props;

        const {visible, editImage, loading, fileList} = this.state;

        const draggerProps = {
            fileList,
            accept,
            name: 'file',
            multiple,
            showUploadList: true,
            listType: 'picture',
            action: '/file',
            headers: this.getHeader(),
            onChange: (info) => {

                let fileList = info.fileList;
                if (!this.props.multiple && fileList.length > 1) {
                    fileList = [fileList[fileList.length - 1]];
                }

                if (autoRemove) {
                    fileList = fileList.filter((item) => {
                        return item.status !== 'done';
                    })
                }

                this.setState({
                    fileList
                });

                const status = info.file.status;
                const response = info.file.response;
                if (status !== 'uploading') {

                }
                if (status === 'done') {
                    if (!hideSuccessMessages) {
                        message.success(`${info.file.name} ${t('file uploaded successfully')}.`);
                    }

                    this.uploadedFilesMap[response.fileId] = info.file;
                    if (this.props.onUpload) {
                        this.props.onUpload(info.file);
                    }

                    if (!fileList.length) {
                        if (this.props.onUploadDone) {
                            this.props.onUploadDone(_.clone(Object.keys(this.uploadedFilesMap).map((key) => this.uploadedFilesMap[key])));
                        }
                        this.uploadedFilesMap = {}
                    }
                } else if (status === 'error') {
                    message.error(`${info.file.name} ${t('file upload failed')}.`);
                }

                this.triggerChange(fileList);

            },
        };

        return <div>
            <Dragger {...draggerProps}>
                {customContent ?
                    customContent
                    :
                    size === 'small' ? <div data-cy={'TEST_30001'}>
                        <p>
                            <Icon type="inbox" style={{marginRight: 16, fontSize: 18}}/>
                            {t('Click or drag file to this area to upload')}
                        </p>
                    </div> : <div data-cy={'TEST_30001'}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                        </p>
                        <p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
                        <p className="ant-upload-hint">
                            {t('Support for a single or bulk upload')}
                        </p>
                    </div>
                }


            </Dragger>

            <ImageEditorModal
                loading={loading}
                onOk={(data) => {

                    const {t} = this.props;

                    this.setState({
                        loading: true
                    });
                    this.uploadBase64File(data, editImage).then((res) => {

                        let fileList = this.state.fileList;
                        if (res.response) {
                            message.success(`${editImage.name} ${t('file uploaded successfully')}.`);
                            fileList = fileList.map(item => {
                                if (item.uid === editImage.uid) {
                                    item.response = res.response;
                                    item.size = res.blob.size;
                                    item.type = res.blob.type;
                                    item.thumbUrl = data;
                                }
                                return item;
                            })
                        }

                        this.triggerChange(fileList);
                        this.setState({
                            fileList,
                            loading: false,
                            visible: false,
                        })
                    }).catch(() => {
                        message.error(`${editImage.name} ${t('file upload failed')}.`);
                        this.setState({
                            loading: false,
                            visible: false,
                        })
                    });

                }}
                onCancel={() => {
                    this.setState({
                        loading: false,
                        visible: false
                    })
                }}
                file={editImage}
                visible={visible}/>
        </div>;
    }

}

export default compose(
    withTranslation()
)(FileUploader)
