import React from 'react';

const BoxIcon = ({ color, size }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
    >
        <path d="M20.7,7c-0.2-0.3-0.4-0.5-0.7-0.7l-7-4C12.7,2.1,12.3,2,12,2s-0.7,0.1-1,0.3l-7,4C3.7,6.5,3.4,6.7,3.3,7
	C3.1,7.3,3,7.7,3,8v8c0,0.4,0.1,0.7,0.3,1s0.4,0.5,0.7,0.7l7,4c0.3,0.2,0.7,0.3,1,0.3s0.7-0.1,1-0.3l7-4c0.3-0.2,0.6-0.4,0.7-0.7
	s0.3-0.6,0.3-1V8C21,7.7,20.9,7.3,20.7,7z M11,19.4L5,16V9.1l6,3.5V19.4z M12,10.8L6.1,7.4L12,4l5.9,3.4L12,10.8z M19,16l-6,3.5
	v-6.8l6-3.5V16z"/>
    </svg>
);

export default BoxIcon;